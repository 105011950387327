import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import styles from "./ScrollDownArrow.module.css";
import { scroller } from "react-scroll";

const scrollToProjects = () => {
  scroller.scrollTo("projects", {
    duration: 1000,
    smooth: true,
    offset: -75,
  });
};

export default function ScrollDownArrow() {
  return (
    <div className={styles.container} onClick={() => scrollToProjects()}>
      <IoIosArrowDown className={styles.icon} />
    </div>
  );
}
