import React from "react";
import styles from "./Title.module.css";

export default function Title() {
  return (
    <div>
      <p className={styles.title}>Martin Tiangco</p>
    </div>
  );
}
