import React from "react";

import MainPage from "./components/MainPage/MainPage";
import "./App.css";

export default function App() {
  return (
    <div className="app">
      <MainPage />
    </div>
  );
}
