import React from "react";
import ScrollUpArrow from "./ScrollUpArrow/ScrollUpArrow";
import styles from "./Footer.module.css";

export default function Footer() {
  return (
    <div className={styles.container}>
      <ScrollUpArrow />
      <div className={styles.info}>
        Martin Tiangco <span className={styles.copyright}>&copy; 2021</span>
      </div>
    </div>
  );
}
