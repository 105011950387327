import React from "react";
import Title from "./Title/Title";
import Description from "./Description/Description";
import Icons from "./Icons/Icons";
import ScrollDownArrow from "./ScrollDownArrow/ScrollDownArrow";
import styles from "./Header.module.css";

export default function Header() {
  return (
    <div name="header" className={styles.container}>
      <Title />
      <Description />
      <Icons />
      <ScrollDownArrow />
    </div>
  );
}
