import React from "react";
import styles from "./Project.module.css";

export default function Project({ info }) {
  const { description, imgSrc, link, title, techStack } = info;

  const onClick = (link) => window.open(link, "_blank");

  let cardClassName = `${styles.container}`;
  cardClassName += link ? ` ${styles.link}` : "";

  return (
    <div
      className={cardClassName}
      onClick={() => {
        if (link) {
          onClick(link);
        }
      }}
    >
      <img className={styles.img} src={imgSrc} alt={title}></img>
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{description}</div>
        <div className={styles.icons}>{techStack.map((icon) => icon)}</div>
      </div>
    </div>
  );
}
