import React from "react";
import { IoIosArrowUp } from "react-icons/io";
import styles from "./ScrollUpArrow.module.css";
import { scroller } from "react-scroll";

const scrollUp = () => {
  scroller.scrollTo("header", {
    duration: 1000,
    smooth: true,
  });
};

export default function ScrollUpArrow() {
  return (
    <div className={styles.container} onClick={() => scrollUp()}>
      <IoIosArrowUp className={styles.icon} />
    </div>
  );
}
