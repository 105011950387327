import React from "react";

import { AiFillGithub, AiOutlineFile } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { VscMail } from "react-icons/vsc";

import styles from "./Icons.module.css";

import cv from "../../../assets/cv.pdf";

export default function Icons() {
  return (
    <div className={styles.container}>
      <AiFillGithub
        onClick={() => {
          window.open("https://github.com/MartinTiangco", "_blank");
        }}
      />
      <FaLinkedin
        onClick={() => {
          window.open("https://www.linkedin.com/in/martintiangco/", "_blank");
        }}
      />
      <VscMail
        onClick={() => {
          window.open("mailto:martintiangco@gmail.com", "_blank");
        }}
      />

      <AiOutlineFile
        onClick={() => {
          window.open(cv, "_blank");
        }}
      />
    </div>
  );
}
