import React from "react";
import Project from "./Project/Project";
import styles from "./Projects.module.css";

import {
  DiReact,
  DiHtml5,
  DiCss3,
  DiJavascript1,
  DiHeroku,
  DiNodejsSmall,
  DiCodeigniter,
} from "react-icons/di";

import kafeKrayonSrc from "./../../assets/Projects/KafeKrayon.PNG";
import pathfinderSrc from "./../../assets/Projects/Pathfinder.PNG";
import eventoSrc from "./../../assets/Projects/Evento.PNG";
import websiteSrc from "./../../assets/Projects/Website.PNG";

const kafeKrayonDescription =
  'Krayon Kafe is an online web application that displays to users with the Google Maps interface the cafes around a desired neighbourhood/area (e.g. Britomart). The project was built over a two-day period in Summer of Tech and ANZ\'s "Create Camp" hackathon-style event. The application uses two APIs - ZenbuAPI for the locaton gathering of the cafes and Google Maps API for the location display to the end-customers.';
const pathfinderDescription =
  "Pathfinder Visualiser visualises pathfinding algorithms (e.g. Dijkstra's Algorithm, Breadth-first Search) on a 2-dimensional grid. When learning about these algorithms, it is usual that we read about it and someone tells us how they work. This application serves as another learning tool to increase understanding of these algorithms. Show, don't tell.";
const eventoDescription =
  "Evento is a WDCC backed project aimed at providing ASPA (Auckland Students Pool Association) with an integrated event registration system that supports automated emails, online payments and other nifty features.";
const websiteDescription =
  "Personal website created with React and deployed on Netlify. You are looking at it right now! It is currently a work-in-progress and I will update it with more interesting stuff later. Stay tuned!";

const kafeKrayonLink = "https://github.com/MartinTiangco/Krayon-Kafe/";
const pathfinderLink = "https://github.com/MartinTiangco/Pathfinder-Visualiser";
const eventoLink = "https://github.com/UoaWDCC/ASPA-EnrollmentForm";

const techStackIconSize = 30;

const projects = [
  {
    description: websiteDescription,
    imgSrc: websiteSrc,
    title: "Personal Website",
    techStack: [<DiReact size={techStackIconSize} />],
  },
  {
    description: pathfinderDescription,
    imgSrc: pathfinderSrc,
    title: "Pathfinder Visualiser",
    link: pathfinderLink,
    techStack: [<DiReact size={techStackIconSize} />],
  },
  {
    description: kafeKrayonDescription,
    imgSrc: kafeKrayonSrc,
    title: "Kafe Krayon",
    link: kafeKrayonLink,
    techStack: [
      <DiHtml5 size={techStackIconSize} />,
      <DiCss3 size={techStackIconSize} />,
      <DiJavascript1 size={techStackIconSize} />,
      <DiHeroku size={techStackIconSize} />,
      <DiNodejsSmall size={techStackIconSize} />,
    ],
  },
  {
    description: eventoDescription,
    imgSrc: eventoSrc,
    title: "Evento",
    link: eventoLink,
    techStack: [
      <DiHtml5 size={techStackIconSize} />,
      <DiCss3 size={techStackIconSize} />,
      <DiJavascript1 size={techStackIconSize} />,
      <DiCodeigniter size={techStackIconSize} />,
    ],
  },
];

export default function Projects() {
  return (
    <div name="projects">
      <div className={styles.title}>PROJECTS</div>
      <div className={styles.container}>
        {projects.map((project) => {
          return <Project key={project.title} info={project} />;
        })}
      </div>
    </div>
  );
}
