import React from "react";
import Header from "../Header/Header";
import Projects from "../Projects/Projects";
import Footer from "../Footer/Footer";

import styles from "./MainPage.module.css";

export default function MainPage() {
  return (
    <div className={styles.container}>
      <Header />
      <Projects />
      <Footer />
    </div>
  );
}
